"use strict";
/*!
 *
 *  expander.js
 *
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var toggler_1 = require("toggler");
var Expander = /** @class */ (function (_super) {
    __extends(Expander, _super);
    function Expander(elem, option) {
        var _this = _super.call(this, elem, option) || this;
        _this.setHeight();
        return _this;
    }
    Expander.prototype.expand = function (e) {
        if (this.setting.viewport[util.viewport]) {
            e.preventDefault();
            var $this = $(e.currentTarget);
            var $target = this.getTarget($this);
            if ($this.is('[aria-selected="true"]')) {
                $this.attr('aria-selected', 'false');
                $target.attr({
                    'aria-expanded': 'false',
                    'aria-hidden': 'true'
                }).stop(true, false).animate({
                    height: 'hide'
                }, 500, 'easeOutQuart');
                // .css('max-height', $target.data('maxHeight'))
                // setTimeout(function(){
                // 	$target.css('max-height', 0);
                // }, 0);
            }
            else {
                if (this.setting.autoClose) {
                    this.reset();
                }
                ;
                $this.attr('aria-selected', 'true');
                $target.attr({
                    'aria-expanded': 'true',
                    'aria-hidden': 'false'
                }).stop(true, false).animate({
                    height: 'show'
                }, 500, 'easeOutQuart');
                // .css('max-height', $target.data('maxHeight'));
            }
            ;
        }
        ;
    };
    Expander.prototype.reset = function () {
        this.$head.filter(function (i, elem) {
            return $(elem).is('[aria-selected="true"]');
        }).attr('aria-selected', 'false');
        this.$body.filter(function (i, elem) {
            return $(elem).is('[aria-expanded="true"]');
        }).attr({
            'aria-expanded': 'false',
            'aria-hidden': 'true'
        }).stop(true, false).animate({
            height: 'hide'
        }, 500, 'easeOutQuart');
        // .css('max-height', 0);
    };
    Expander.prototype.setHeight = function () {
        this.$body.each(function (i, elem) {
            // let h = elem.scrollHeight;
            // let maxHeight = h !== undefined ? h : '100%';
            // $(elem).data('maxHeight', maxHeight);
            // $(elem).attr('data-placeholder', maxHeight);
            if ($(elem).is('[aria-expanded="true"]')) {
                $(elem).show(0);
                // $(elem).css('max-height', maxHeight);
            }
            ;
        });
    };
    Expander.prototype.setEvent = function () {
        var _this = this;
        _super.prototype.setEvent.call(this);
        // $(window).on('resize', () => {
        // 	this.setHeight();
        // });
        $(window).on('viewportChanged', function () {
            _this.reset();
        });
        // this.$body.on('transitionend', (e) => {
        // 	if ($(e.currentTarget).css('max-height')) {
        // 		$(e.currentTarget).removeAttr('style');
        // 	};
        // });
        this.$elem.on('close', function () {
            var self = _this;
            _this.$head.attr('aria-selected', 'false');
            _this.$body.attr({
                'aria-expanded': 'false',
                'aria-hidden': 'true'
            }).stop(true, false).animate({
                height: 'hide'
            }, 500, 'easeOutQuart');
            // .css('max-height', this.$body.data('maxHeight'))
            // setTimeout(function () {
            // 	self.$body.css('max-height', 0);
            // }, 0);
        });
    };
    return Expander;
}(toggler_1.Toggler));
$.fn.expander = function (option) {
    // let $elems = this;
    // $(document).on('click', (e) => {
    // 	if (!$(e.target).closest($elems.selector).length) {
    // 		$elems.trigger('close');
    // 	};
    // });
    return this.each(function (i, elem) {
        new Expander(elem, option ? option : {});
    });
};
