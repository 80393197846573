"use strict";
/*!
 *
 *  expander.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var globalMenu = /** @class */ (function () {
    function globalMenu(global, canHover) {
        var _this = this;
        this.canHover = true;
        this.$global = $(global);
        this.$item = this.$global.children('.st-Global_Item').filter(function (i, elem) {
            if ($(elem).find('.st-Global_Body').length) {
                return true;
            }
            ;
        });
        this.$btn = this.$item.find('.st-Global_Btn');
        this.canHover = canHover === undefined ? this.canHover : canHover;
        if (this.canHover) {
            this.$item.on('mouseenter', function (e) {
                if (!util.isTouchDevice && util.viewport !== 'phone') {
                    var $btn = $(e.currentTarget).find('.st-Global_Btn');
                    $btn.trigger('mouseup');
                }
                ;
            }).on('mouseleave', function (e) {
                if (!util.isTouchDevice && util.viewport !== 'phone') {
                    var $btn = $(e.currentTarget).find('.st-Global_Btn');
                    $btn.trigger('mouseup');
                }
                ;
            });
        }
        ;
        this.$btn.on('mouseup touchend', function (e) {
            e.preventDefault();
            var $elem = $(e.currentTarget);
            if ($elem.is('[aria-selected="false"]')) {
                _this.open($elem);
            }
            else {
                _this.close($elem);
            }
            ;
        });
        $(document).on('mouseup touchend', function (e) {
            if (!$(e.target).closest(_this.$item).length) {
                var $current = _this.$item.filter(function (i, elem) { return $(elem).is('[aria-expanded="true"]'); });
                if ($current.length > 0) {
                    _this.close($current.find('.st-Global_Btn'));
                }
                ;
            }
            ;
        });
    }
    globalMenu.prototype.open = function ($btn) {
        var $item = $btn.closest('.st-Global_Item');
        var $body = $item.find('.st-Global_Body');
        var h = $body.children().height();
        var $current = this.$item.filter(function (i, elem) { return $(elem).is('[aria-expanded="true"]'); });
        if ($current.length > 0) {
            this.close($current.find('.st-Global_Btn'));
        }
        ;
        $item.attr('aria-expanded', 'true');
        $btn.attr('aria-selected', 'true');
        $body.css({
            'max-height': h
        });
    };
    globalMenu.prototype.close = function ($btn) {
        var $item = $btn.closest('.st-Global_Item');
        var $body = $item.find('.st-Global_Body');
        $item.attr('aria-expanded', 'false');
        $btn.attr('aria-selected', 'false');
        $body.css({
            'max-height': 0
        });
    };
    return globalMenu;
}());
exports.globalMenu = globalMenu;
var fixedMenu = /** @class */ (function () {
    function fixedMenu() {
        var _this = this;
        this.$menu = $('#Menu');
        this.isFixed = false;
        this.top = this.$menu.offset().top;
        $(window).on('scroll', function (e) {
            var scrollTop = $(e.currentTarget).scrollTop();
            if (!_this.isFixed && scrollTop > _this.top) {
                _this.isFixed = true;
                var _top = 2 - scrollTop;
                if (_top < 0) {
                    _top = 0;
                }
                ;
                _this.$menu.css({
                    top: _top
                });
            }
            ;
            if (_this.isFixed && scrollTop <= _this.top) {
                _this.isFixed = false;
                var _top = 2 - scrollTop;
                if (_top >= 2) {
                    _top = 2;
                }
                ;
                _this.$menu.css({
                    top: _top
                });
            }
            ;
        });
    }
    return fixedMenu;
}());
exports.fixedMenu = fixedMenu;
var Menu = /** @class */ (function () {
    function Menu(menu, btn) {
        var _this = this;
        this.$menu = $(menu);
        this.$btn = $(btn);
        this.$btn.on('click', function (e) {
            e.preventDefault();
            var $this = $(e.currentTarget);
            if ($this.is('[aria-selected="false"]')) {
                _this.open($this);
            }
            else {
                _this.close($this);
            }
            ;
        });
        $(document).on('mouseup touchend', function (e) {
            if (_this.$btn.is('[aria-selected="true"]') && !$(e.target).closest(_this.$menu).length) {
                _this.close(_this.$btn);
            }
            ;
        });
    }
    Menu.prototype.open = function ($elem) {
        var $body = $($elem.attr('href'));
        $elem.attr('aria-selected', 'true');
        if (!$body.closest('#Sticky').length) {
            $body.stop(true, false).animate({
                height: 'show'
            }, 500, 'easeOutQuart', function () {
                $(this).css({
                    height: null
                });
            });
        }
        else {
            $body.attr('aria-hidden', 'false');
        }
        ;
        this.$menu.attr('aria-expanded', 'true');
        $('body').addClass('-menu-open');
    };
    Menu.prototype.close = function ($elem) {
        var $body = $($elem.attr('href'));
        $elem.attr('aria-selected', 'false');
        if (!$body.closest('#Sticky').length) {
            $body.stop(true, false).animate({
                height: 'hide'
            }, 500, 'easeOutQuart');
        }
        else {
            $body.attr('aria-hidden', 'true');
        }
        ;
        this.$menu.attr('aria-expanded', 'false');
        $('body').removeClass('-menu-open');
    };
    return Menu;
}());
exports.Menu = Menu;
var cloneMenu = /** @class */ (function () {
    function cloneMenu() {
        var _this = this;
        this.$container = $('#StickyInner');
        this.$origin = $('#Menu');
        this.$clone = this.$origin.clone(false);
        this.changeID(this.$clone);
        this.$clone.find('[id]').each(function (index, elem) {
            if ($(elem).is('#MenuBtn')) {
                _this.changeHref($(elem));
            }
            ;
            _this.changeID($(elem));
        });
        this.$container.append(this.$clone);
        new Menu('#MenuClone', '#MenuBtnClone');
        new globalMenu('#GlobalClone', false);
    }
    cloneMenu.prototype.changeID = function ($elem) {
        var id = $elem.attr('id') + 'Clone';
        $elem.attr('id', id);
    };
    cloneMenu.prototype.changeHref = function ($elem) {
        var href = $elem.attr('href') + 'Clone';
        $elem.attr('href', href);
    };
    ;
    return cloneMenu;
}());
exports.cloneMenu = cloneMenu;
