"use strict";
/*!
 *
 *  toshi.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var toshiMainImages = /** @class */ (function () {
    function toshiMainImages() {
        var _this = this;
        this.$elem = $('#toshiMainImages');
        this.$main = $('#toshiMain');
        this.$loader = $('#toshiMainLoader');
        this.$dots = $('#toshiMainDots');
        this.$elem.on('init', function () {
            _this.$main.addClass('-inited');
            _this.$loader.fadeOut(700);
        });
        this.$elem.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            arrows: false,
            dots: true,
            autoplay: true,
            autoplaySpeed: 3000,
            appendDots: this.$dots,
            speed: 2000,
            pauseOnFocus: false,
            pauseOnHover: false
        });
    }
    return toshiMainImages;
}());
exports.toshiMainImages = toshiMainImages;
;
var toshiMarquee = /** @class */ (function () {
    function toshiMarquee() {
        this.$marquee = $('.toshi-Area_Marquee_Txt');
        this.$marquee.marquee({
            duration: 15000,
            gap: 0,
            delayBeforeStart: 0,
            direction: 'left',
            duplicated: true,
            startVisible: true
        });
    }
    return toshiMarquee;
}());
exports.toshiMarquee = toshiMarquee;
